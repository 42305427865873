import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import {
  findProducts,
  getAllProducts,
  getProductsDropDownValues,
  setVisibleCount,
} from "../../redux/action/addProduct";
import "./Dashboard.css"; // Import CSS file for styling
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import filter from "../../images/icons/filter.svg";
import filterGif from "../../images/icons/filter.gif";
import close from "../../images/icons/close.svg";
import DropdownWithCheckbox from "./DropdownWithCheckbox";
import search from "../../images/icons/search.svg";
import BestSeller from "../../images/icons/bestSeller.svg";
import space from "../../images/space.gif";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import {
  setSelectedFilteredProducts,
  updateBuildDemoStatus,
} from "../../redux/action/others";
import Popup from "reactjs-popup";
import { Checkbox, FormControlLabel } from "@mui/material";
import PptGenerator from "../../PPTGenerator/PptGenerator_no_fabric_info_19-10-2024";
import { addProductsToIndexedDB } from "../IndexDB";
import { Close } from "@mui/icons-material";
import { MdClose } from "react-icons/md";
import stepCompleted from "../../images/step-conpleted.png";
import nextStep from "../../images/next-step.png";
import stepOngoing from "../../images/step-ongoing.png";
import checkBoxIcon from "../../images/check-box.png";
import checkBoxCheckedIcon from "../../images/check-box-checked.png";
import { Toaster, toast } from "react-hot-toast";
// import "../../font.css";

const drawerWidth = 220;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  fontFamily:"Calibri",
}));


const imageAttributeDisplayNames = {
  front_image_base64: "Front Image",
  back_image_base64: "Back Image",
  closeup_images_base64_1: "Detail Image 1",
  closeup_images_base64_2: "Detail Image 2",
  closeup_images_base64_3: "Detail Image 3",
};
const fabricAttributeDisplayNamesLabel = {
  fabric_ref: "Fabric Reference. No",
  content: "Content",
  construction: "Construction",
  fabric_supplier: "Fabric Mill/Supplier",
  shrinkage: "Shrinkage",
  weight: "Weight",
  cw: "Cuttable Width",
  fabric_price: "Fabric Price",
  yardage:"Yardage"
};

const extractFabricInfoTitle=(fabricInfoKey)=>{
  const number = fabricInfoKey.split('_').pop();
  return `Fabric ${number} Info`;
}

const Dashboard = ({
  getAllProducts,
  loading,
  getProductsDropDownValues,
  dropdownValues,
  selectedGender,
  setSelectedGender,
  findProducts,
  selectedProductsData,
  buildDemoStatus,
  updateBuildDemoStatus,
  setSelectedFilteredProducts,
  selectedFilteredProductsData,
  setVisibleCount,
  visibleCount,
  setActiveItem,
  setDemoSelected,
  demoSelected,
  demoLoading,
}) => {
  // const [selectedIds, setSelectedIds] = useState([]);
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  console.log("buildDemoStatus", buildDemoStatus);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [checkBoxClicked,setCheckBoxClicked]=useState(false);
  const [selectedSeason, setSelectedSeason] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);

  const [filteredProducts, setFilterProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const productRefs = useRef({});
  const location = useLocation();
  const [showLoading, setShowLoading] = useState(true); // New state to control loading spinner

  const { gender } = location.state || {}; // Destructure gender safely
  const [filterIsHovered, setfilterIsHovered] = useState(false);
  const scrollContainerRef = useRef(null);
  const [openDownloadOptions, setOpenDownloadOptions] = useState(false);
  const [openDownloadOptions1, setOpenDownloadOptions1] = useState(false);
  const [filteredProductsData, setFilteredProductsData] = useState([]);
  const closeModal = () => setOpenDownloadOptions(false);
  const closeModal1 = () => setOpenDownloadOptions1(false);
  const [checkedAttributes, setCheckedAttributes] = useState({
    _id: true,
    description: true,
    season: true,
    product_style: true,
    gender: true,
    categories: true,
    best_seller: true,
    front_image_base64: true,
    back_image_base64: true,
  });
  console.log("selectedGender", selectedGender);
  console.log("role", role); // Fixed typo: changed selectedGender to gender

  useEffect(() => {
    sessionStorage.setItem("previousUrl", window.location.href);
  }, [location]);
  useEffect(() => {
    if (gender) {
      if (gender === "Kids") {
        setSelectedGender(["Boys", "Girls"]);
      } else if (gender === "All") {
        setSelectedGender([]);
      } else {
        setSelectedGender([gender]);
      }
    }
  }, [gender, setSelectedGender]);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClear = () => {
    setSearchTerm("");
    setSelectedCategories([]);
    setSelectedSeason([]);
    setSelectedBrand([]);
    setSelectedGender([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const userDetails = getUserDetailsFromLocalStorage();
      if (userDetails?.user) {
        const products = await getAllProducts(
          userDetails?.user?._id,
          userDetails?.user?.role
        );
        setAllProducts(products);
        getProductsDropDownValues();
        setRole(userDetails.user.role);

        // Show loading spinner for an additional half second
        setTimeout(() => {
          setShowLoading(false);
        }, 500);
      }
    };

    fetchData();
  }, [getAllProducts, getProductsDropDownValues]);



  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      allProducts &&
      Array.isArray(allProducts?.data) &&
      allProducts?.data?.length > 0
    ) {
      handleSearchClick();
    } else {
      setFilterProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = useCallback(() => {
    if (
      allProducts &&
      Array.isArray(allProducts?.data) &&
      allProducts?.data?.length > 0
    ) {
      const filter = allProducts.data.filter((product) => {
        const content = product.fabric_info[0].content.toLowerCase();
        const description = product.description.toLowerCase();
        const searchTerms = searchTerm.toLowerCase().split(" ");

        // Check if all search terms are found in content or description
        return searchTerms.every(
          (term) => content.includes(term) || description.includes(term)
        );
      });

      setFilterProducts(filter);
    } else {
      console.log("No products found.");
      setFilterProducts([]);
    }
  }, [allProducts, searchTerm]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  useEffect(() => {
    // Get the last viewed product ID from localStorage
    const lastViewedProductId = localStorage.getItem("lastViewedProduct");

    if (lastViewedProductId && productRefs.current[lastViewedProductId]) {
      // Scroll to the product with the matching ID
      productRefs.current[lastViewedProductId].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Use a timeout to delay the setting of localStorage
      setTimeout(() => {
        localStorage.setItem("lastViewedProduct", null);
      }, 1000); // Adjust timeout duration as needed
    }
  }, [filteredProducts]);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />{" "}
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />{" "}
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (showLoading || demoLoading || loading) {
    return <GradientCircularProgress />;
  }

  console.log("filteredProducts", filteredProducts);

  const products = filteredProducts.filter((product) => {
    const genderFilter =
      selectedGender.length === 0 || selectedGender.includes(product.gender);

    const seasonFilter =
      selectedSeason.length === 0 || selectedSeason.includes(product.season);
      const brandFilter =
      selectedBrand.length === 0 || selectedBrand.includes(product.brand);
    const categoryFilter =
      selectedCategories.length === 0 ||
      selectedCategories.includes(product.categories);

    return genderFilter && seasonFilter && categoryFilter && brandFilter;
  });
  console.log("allProducts", allProducts);
  const navigateToProductDetails = (productId) => {
    localStorage.setItem("lastViewedProduct", productId);

    if (!buildDemoStatus) {
      navigate(`/productdetails/${productId}`);
    }
  };
  // selectedGender = { selectedGender };
  const addProductstoBuild = (productId) => {
    setSelectedProducts((prevSelectedProducts) => {
      if (prevSelectedProducts.includes(productId)) {
        // Remove productId from array if it already exists
        return prevSelectedProducts.filter((id) => id !== productId);
      } else {
        // Add productId to array if it doesn't exist
        return [...prevSelectedProducts, productId];
      }
    });
  };
  console.log("selectedProductId", selectedProducts);
  console.log("popup-open", openDownloadOptions);
  const getDistinctFields = (data) => {
    const fieldsSet = new Set(); // To store distinct common fields
    let maxFabricInfo = []; // Array to hold the fabric_info from the product with the maximum fabric_info length
  
    // Loop through all products to gather common fields and find the max fabric_info
    data.forEach((item) => {
      // Add common fields from each product
      Object.keys(item).forEach((key) => {
        if (key !== "fabric_info") { // Ignore fabric_info for now
          fieldsSet.add(key);
        }
      });
  
      // Find the product with the largest fabric_info array
      if (item.fabric_info && item.fabric_info.length > maxFabricInfo.length) {
        maxFabricInfo = item.fabric_info;
      }
    });
  
    // Now process the fabric_info array from the product with the max length
    const fabricInfoFields = maxFabricInfo.map((fabric, index) => {
      const fabricKey = `fabric_info_${index + 1}`;
      const fabricFields = []; // Array to hold fields for the current fabric
  
      // For the first fabric, include all keys
      if (index === 0) {
        Object.keys(fabric).forEach((key) => {
          fabricFields.push(key);
        });
      } else {
        // For subsequent fabrics, include only specific keys
        ["fabric_ref", "content", "construction"].forEach((key) => {
          if (key in fabric) {
            fabricFields.push(key);
          }
        });
      }
  
      return { [fabricKey]: fabricFields };
    });
  
    // Convert the fieldsSet back to an array and append fabric_info fields
    return [...fieldsSet, ...fabricInfoFields];
  };
  
  const distinctProductsFields = selectedProductsData
    ? getDistinctFields(selectedProductsData)
    : [];
  console.log("distinctProductFields",distinctProductsFields);
  console.log(selectedProductsData);
  console.log("selectedProducts", selectedProducts);
  console.log("checkedAttributes", checkedAttributes);
  const loadMore = () => {
    setVisibleCount(visibleCount + 20);
  };
  function getDistinctImageFields(data) {
    let maxCloseupImages = 0;

    // Determine the maximum length of closeup_images array
    data.forEach((product) => {
      if (
        product.closeup_images_base64 &&
        product.closeup_images_base64.length > maxCloseupImages
      ) {
        maxCloseupImages = product.closeup_images_base64.length;
      }
    });

    // Create the fields array with mandatory fields
    const fields = ["front_image_base64", "back_image_base64"];

    // Add closeup_image fields with indexes
    for (let i = 0; i < maxCloseupImages; i++) {
      fields.push(`closeup_images_base64_${i + 1}`);
    }

    return fields;
  }
  const distinctProductImageFields = selectedProductsData
    ? getDistinctImageFields(selectedProductsData)
    : [];
  console.log(distinctProductImageFields);
  // selectedGender = { selectedGender };
  const buildDemoConfirmClick = () => {
    findProducts(selectedProducts);
    setOpenDownloadOptions((o) => !o);
  };
  const buildDemoCancelClick = () => {
    localStorage.setItem("activeItem", "");
    setActiveItem("");
    updateBuildDemoStatus(false);
    setOpenDownloadOptions(false);
    setSelectedProducts([]);
    setDemoSelected(false);
  };
  const buildDemoPopupCloseClick=()=>{
    localStorage.setItem("activeItem", "");
    setActiveItem("");
    updateBuildDemoStatus(false);
    setOpenDownloadOptions(false);
    setSelectedProducts([]);
    setDemoSelected(false);
    setOpenDownloadOptions(false);
    setOpenDownloadOptions1(false);
  }
  // const handleCheckboxChange = (attribute, fabricInfoKey = null, fabricField = null) => {
  //   setCheckBoxClicked(false);
  
  //   setCheckedAttributes((prevState) => {
  //     const newState = { ...prevState };
  //     let selectionHistory = [...(prevState.selectionHistory || [])];
  
  //     // Handle fabric_info attributes
  //     if (fabricInfoKey && fabricField) {
  //       const fabricIndex = fabricInfoKey.split('_')[2] - 1; // Extract fabric_info index (e.g., 1 for fabric_info_1)
  
  //       // Ensure fabric_info array exists in the newState
  //       if (!newState.fabric_info) {
  //         newState.fabric_info = [];
  //       }
  
  //       // Initialize empty objects for missing fabric_info indices
  //       while (newState.fabric_info.length <= fabricIndex) {
  //         newState.fabric_info.push({});
  //       }
  
  //       const fabricInfoAttribute = fabricField;
  
  //       if (newState.fabric_info[fabricIndex][fabricInfoAttribute]) {
  //         // Unselect fabric_info attribute
  //         delete newState.fabric_info[fabricIndex][fabricInfoAttribute];
  //         selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.${fabricField}`);
  
  //         // Auto-deselect dependent fields
  //         if (fabricInfoAttribute === "cw" && newState.fabric_info[fabricIndex]["cw_unit"]) {
  //           delete newState.fabric_info[fabricIndex]["cw_unit"];  // Auto-unselect cw_unit
  //           selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.cw_unit`);
  //         }
  //         if (fabricInfoAttribute === "fabric_price" && newState.fabric_info[fabricIndex]["fabric_currency"]) {
  //           delete newState.fabric_info[fabricIndex]["fabric_currency"];  // Auto-unselect fabric_currency
  //           selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.fabric_currency`);
  //         }
  //       } else {
  //         // Select fabric_info attribute
  //         newState.fabric_info[fabricIndex][fabricInfoAttribute] = true;
  //         if (!selectionHistory.includes(`${fabricInfoKey}.${fabricField}`)) {
  //           selectionHistory.push(`${fabricInfoKey}.${fabricField}`);
  //         }
  
  //         // Auto-select dependent fields
  //         if (fabricInfoAttribute === "cw" && !newState.fabric_info[fabricIndex]["cw_unit"]) {
  //           newState.fabric_info[fabricIndex]["cw_unit"] = true;  // Auto-select cw_unit
  //         }
  //         if (fabricInfoAttribute === "fabric_price" && !newState.fabric_info[fabricIndex]["fabric_currency"]) {
  //           newState.fabric_info[fabricIndex]["fabric_currency"] = true;  // Auto-select fabric_currency
  //         }
  //       }
  
  //       // Remove the fabric index if it's empty after deselection
  //       if (Object.keys(newState.fabric_info[fabricIndex]).length === 0) {
  //         newState.fabric_info.splice(fabricIndex, 1);
  //       }
  //     } else {
  //       // Handle non-fabric_info attributes
  //       if (newState[attribute]) {
  //         // Unselect attribute
  //         newState[attribute] = false;
  //         selectionHistory = selectionHistory.filter((item) => item !== attribute);
  //       } else {
  //         // Select attribute
  //         newState[attribute] = true;
  //         if (!selectionHistory.includes(attribute)) {
  //           selectionHistory.push(attribute);
  //         }
  //       }
  //     }
  
  //     return { ...newState, selectionHistory };
  //   });
  // };
  const handleCheckboxChange = (attribute, fabricInfoKey = null, fabricField = null) => {
    setCheckBoxClicked(false);
  
    setCheckedAttributes((prevState) => {
      const newState = { ...prevState };
      let selectionHistory = [...(prevState.selectionHistory || [])];
  
      // Check if the attribute is a special field
      const isSpecialField = attribute?.startsWith("closeup_images_base64") ||
        attribute === "front_image_base64" ||
        attribute === "back_image_base64";
  
      // Check if any closeup images are selected
      const closeupImageKeys = Object.keys(newState).filter(key => key.startsWith("closeup_images_base64"));
      const closeupImageSelectedCount = closeupImageKeys.filter(key => newState[key]).length;
  
      // Count the total number of selected non-special fields (including fabric_info fields)
      let nonSpecialSelectedCount = Object.keys(newState)
        .filter(key => !key.startsWith("closeup_images_base64") &&
          key !== "front_image_base64" &&
          key !== "back_image_base64" &&
          key !== "best_seller"&&
          newState[key] === true)
        .length;
  
      // Include fabric_info keys in the count, excluding cw_unit, weight_unit, and fabric_currency
      if (newState.fabric_info) {
        newState.fabric_info.forEach((fabricInfo) => {
          Object.keys(fabricInfo).forEach((key) => {
            if (fabricInfo[key] === true &&
              key !== "cw_unit" &&
              key !== "weight_unit" &&
              key !== "fabric_currency") {
              nonSpecialSelectedCount++;
            }
          });
        });
      }
  
      // Handle the case where more than 15 fields are selected (regardless of type)
      if (nonSpecialSelectedCount >= 15 && closeupImageSelectedCount>0) {
        const mostRecentlySelected = selectionHistory
          .filter(item => !item.startsWith("closeup_images_base64") &&
                          item !== "front_image_base64" &&
                          item !== "back_image_base64")
          .pop(); // Get the most recently selected item (last in the history)
  
        if (mostRecentlySelected) {
          const [fabricKeyPrefix, fabricKey] = mostRecentlySelected.split('.');
          const fabricIndex = fabricKeyPrefix.match(/\d+/)?.[0] - 1;
  
          if (fabricKey && !isNaN(fabricIndex) && newState.fabric_info[fabricIndex]) {
            // Unselect fabric_info attribute
            delete newState.fabric_info[fabricIndex][fabricKey];
            if (Object.keys(newState.fabric_info[fabricIndex]).length === 0) {
              newState.fabric_info.splice(fabricIndex, 1);
            }
          } else {
            // Unselect top-level attribute
            newState[mostRecentlySelected] = false;
          }
          selectionHistory = selectionHistory.filter((item) => item !== mostRecentlySelected);
        }
      }
  
      console.log("nonSpecialSelectedCount", nonSpecialSelectedCount);
  
      // Handle fabric_info attributes
      if (fabricInfoKey && fabricField) {
        const fabricIndex = fabricInfoKey.split('_')[2] - 1;
  
        // Ensure fabric_info array exists in the newState
        if (!newState.fabric_info) {
          newState.fabric_info = [];
        }
  
        // Create a deep copy of the fabric_info array to avoid mutating the state directly
        const fabricInfoCopy = [...newState.fabric_info];
  
        // Initialize empty objects for missing fabric_info indices
        while (fabricInfoCopy.length <= fabricIndex) {
          fabricInfoCopy.push({});
        }
  
        const fabricInfoAttribute = fabricField;
  
        if (fabricInfoCopy[fabricIndex][fabricInfoAttribute]) {
          // Unselect fabric_info attribute
          delete fabricInfoCopy[fabricIndex][fabricInfoAttribute];
          selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.${fabricField}`);
  
          // Auto-deselect dependent fields
          if (fabricInfoAttribute === "cw" && fabricInfoCopy[fabricIndex]["cw_unit"]) {
            delete fabricInfoCopy[fabricIndex]["cw_unit"];
            selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.cw_unit`);
          }
          if (fabricInfoAttribute === "fabric_price" && fabricInfoCopy[fabricIndex]["fabric_currency"]) {
            delete fabricInfoCopy[fabricIndex]["fabric_currency"];
            selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.fabric_currency`);
          }
          if (fabricInfoAttribute === "weight" && fabricInfoCopy[fabricIndex]["weight_unit"]) {
            delete fabricInfoCopy[fabricIndex]["weight_unit"];
            selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.weight_unit`);
          }
        } else {
          // Select fabric_info attribute
          fabricInfoCopy[fabricIndex][fabricInfoAttribute] = true;
          if (!selectionHistory.includes(`${fabricInfoKey}.${fabricField}`)) {
            selectionHistory.push(`${fabricInfoKey}.${fabricField}`);
          }
  
          // Auto-select dependent fields
          if (fabricInfoAttribute === "cw" && !fabricInfoCopy[fabricIndex]["cw_unit"]) {
            fabricInfoCopy[fabricIndex]["cw_unit"] = true;
          }
          if (fabricInfoAttribute === "fabric_price" && !fabricInfoCopy[fabricIndex]["fabric_currency"]) {
            fabricInfoCopy[fabricIndex]["fabric_currency"] = true;
          }
          if (fabricInfoAttribute === "weight" && !fabricInfoCopy[fabricIndex]["weight_unit"]) {
            fabricInfoCopy[fabricIndex]["weight_unit"] = true;
          }
        }
  
        // Remove the fabric index if it's empty after deselection
        if (Object.keys(fabricInfoCopy[fabricIndex]).length === 0) {
          fabricInfoCopy.splice(fabricIndex, 1);
        }
  
        // Update fabric_info in the newState with the deep copy
        newState.fabric_info = fabricInfoCopy;
  
      } else if (isSpecialField) {
        // Handle special fields
        if (newState[attribute]) {
          // Unselecting special field
          newState[attribute] = false;
          selectionHistory = selectionHistory.filter((item) => item !== attribute);
        } else {
          // Selecting special field
          newState[attribute] = true;
          if (!selectionHistory.includes(attribute)) {
            selectionHistory.push(attribute);
          }
  
          // Handle the limit for closeup images
          if (attribute.startsWith("closeup_images_base64")) {
            if (closeupImageSelectedCount >= 2) {
              // Unselect the earliest selected closeup image
              const earliestSelectedCloseup = selectionHistory
                .filter(item => item.startsWith("closeup_images_base64"))
                .shift();
              if (earliestSelectedCloseup) {
                newState[earliestSelectedCloseup] = false;
                selectionHistory = selectionHistory.filter((item) => item !== earliestSelectedCloseup);
              }
            }
          }
        }
      } else {
        // Handle non-fabric_info and non-special fields
        if (newState[attribute]) {
          // Unselect attribute
          newState[attribute] = false;
          selectionHistory = selectionHistory.filter((item) => item !== attribute);
        } else {
          // Select non-special field
          newState[attribute] = true;
          if (!selectionHistory.includes(attribute)) {
            selectionHistory.push(attribute);
          }
        }
      }
  
      return { ...newState, selectionHistory };
    });
  };
  const handleSelectAll = () => {
    setCheckBoxClicked(true);
    setCheckedAttributes((prevCheckedAttributes) => {
      const newCheckedAttributes = { ...prevCheckedAttributes };
      const selectionHistory = [...(prevCheckedAttributes.selectionHistory || [])];
      console.log(newCheckedAttributes,selectionHistory)
  
      // Count selected closeup images
      const closeupImageKeys = Object.keys(newCheckedAttributes).filter(key => key.startsWith("closeup_images_base64"));
      const closeupImageSelectedCount = closeupImageKeys.filter(key => newCheckedAttributes[key]).length;
  
      // Count selected non-special fields
      let nonSpecialSelectedCount = Object.keys(newCheckedAttributes).filter(key => 
        !key.startsWith("closeup_images_base64") &&
        key !== "front_image_base64" &&
        key !== "back_image_base64" &&
        newCheckedAttributes[key] === true).length;
  
      // Iterate through filteredProductAttributes to select them
      filteredProductAttributes.forEach((attribute) => {
        // Check if the attribute is a string (simple field)
        if (typeof attribute === "string") {
            newCheckedAttributes[attribute] = true;
            if (!selectionHistory.includes(attribute)) {
              selectionHistory.push(attribute);
            }
            nonSpecialSelectedCount++; // Increment count for non-special fields
        } 
        
        // Check if the attribute is an object (like fabric_info)
        else if (typeof attribute === "object") {
          Object.entries(attribute).forEach(([key, subAttributes]) => {
            if (Array.isArray(subAttributes)) {
              // Ensure fabric_info exists and is an array
              if (!newCheckedAttributes.fabric_info) {
                newCheckedAttributes.fabric_info = [];
              }
        
              // Find the index of the fabric_info object we are working with
              const fabricInfoIndex = parseInt(key.split('_')[2]) - 1; // Assuming the key is like "fabric_info_1"
        
              // Ensure the correct fabric_info object exists
              if (!newCheckedAttributes.fabric_info[fabricInfoIndex]) {
                newCheckedAttributes.fabric_info[fabricInfoIndex] = {};
              }
        
              // Iterate over each sub-attribute
              subAttributes.forEach((subAttribute) => {
                // Set the sub-attribute to true
                newCheckedAttributes.fabric_info[fabricInfoIndex][subAttribute] = true;
        
                // Add to selection history with appropriate naming convention
                const selectionKey = `${key}.${subAttribute}`; // e.g., "fabric_info_1.fabric_ref"
                if (!selectionHistory.includes(selectionKey)) {
                  selectionHistory.push(selectionKey);
                }
        
                nonSpecialSelectedCount++; // Increment count for non-special fields
              });
        
              // Set additional attributes to true
              const additionalAttributes = ['cw_unit', 'fabric_currency', 'weight_unit'];
        
              additionalAttributes.forEach(attr => {
                newCheckedAttributes.fabric_info[fabricInfoIndex][attr] = true;
        
                // Add to selection history
                const selectionKey = `${key}.${attr}`; // e.g., "fabric_info_1.cw_unit"
                if (!selectionHistory.includes(selectionKey)) {
                  selectionHistory.push(selectionKey);
                }
        
                nonSpecialSelectedCount++; // Increment count for non-special fields
              });
            }
          });
        }
      });
  
      // Handle the case where more than 15 fields are selected (regardless of type)
      // if (nonSpecialSelectedCount >= 15 && closeupImageSelectedCount > 0) {
      //   const mostRecentlySelected = selectionHistory
      //     .filter(item => !item.startsWith("closeup_images_base64") &&
      //                     item !== "front_image_base64" &&
      //                     item !== "back_image_base64")
      //     .pop(); // Get the most recently selected item (last in the history)
  
      //   if (mostRecentlySelected) {
      //     // Unselect the most recently selected attribute
      //     newCheckedAttributes[mostRecentlySelected] = false;
      //     selectionHistory = selectionHistory.filter(item => item !== mostRecentlySelected);
      //   }
      // }
  
      // Return updated checkedAttributes with the new selection history
      return { ...newCheckedAttributes, selectionHistory };
    });
  };
  console.log("distinctProductImageFields", distinctProductImageFields);
  console.log("checkedAttributes", checkedAttributes);
  const handleSelectAllImages = () => {
    setCheckedAttributes((prevCheckedAttributes) => {
      const newCheckedAttributes = { ...prevCheckedAttributes };
      distinctProductImageFields.forEach((attribute) => {
        newCheckedAttributes[attribute] = true;
      });
      return newCheckedAttributes;
    });
  };
  const buildDemoPopup1ConfirmClick = () => {
    setOpenDownloadOptions1(true);
    setOpenDownloadOptions(false);
    // if(distinctProductImageFields?.length > 2){
    // }
  };
  const buildDemoPopup2BackClick = () => {
    setOpenDownloadOptions(true);
    setOpenDownloadOptions1(false);
    // setCheckedAttributes({
    //   _id: true,
    //   description: true,
    //   season: true,
    //   product_style: true,
    //   gender: true,
    //   categories: true,
    //   best_seller: true,
    //   phase: true,
    //   front_image_base64: true,
    //   back_image_base64: true,
    // });
  };
  const buildDemoPopup2ConfirmClick = async () => {
    const filterData = (data, filter) => {
      // Extract numbers from closeup_images_base64 keys and store them in an array
      const closeupImageIndexes = Object.keys(filter)
        .filter(
          (key) => key.startsWith("closeup_images_base64_") && filter[key]
        )
        .map((key) => parseInt(key.split("_")[3], 10) - 1); // Subtract 1 to match array index
  
      return data.map((item) => {
        const filteredItem = {};
  
        // Filter the main product properties
        Object.keys(filter).forEach((key) => {
          if (key !== 'fabric_info' && item.hasOwnProperty(key) && filter[key]) {
            filteredItem[key] = item[key];
          }
        });
  
        // Handle closeup_images_base64 array based on selected indexes
        if (closeupImageIndexes.length > 0 && item.closeup_images_base64) {
          filteredItem.closeup_images_base64 = closeupImageIndexes
            .map((index) => item.closeup_images_base64[index])
            .filter((image) => image !== undefined); // Filter out undefined images
        }
  
        // Handle fabric_info separately if it's part of the filter
        if (filter.fabric_info && Array.isArray(item.fabric_info)) {
          filteredItem.fabric_info = item.fabric_info.map((fabric, index) => {
            const fabricFilter = filter.fabric_info[index] || {};
            const filteredFabric = {};
            Object.keys(fabricFilter).forEach((key) => {
              if (fabric.hasOwnProperty(key) && fabricFilter[key]) {
                filteredFabric[key] = fabric[key];
              }
            });
            return filteredFabric;
          }).filter(fabric => Object.keys(fabric).length > 0); // Remove empty fabric objects
        }
  
        return filteredItem;
      });
    };
  
    // Filter the product data based on the selected filters
    const filteredProductsDataRedux = filterData(
      selectedProductsData,
      checkedAttributes
    );
  
    console.log(filteredProductsDataRedux);
    try {
      await addProductsToIndexedDB(
        "productsDB",
        "products",
        1,
        filteredProductsDataRedux
      );
    } catch (error) {
      console.error("Error saving to IndexedDB:", error);
    }

    // Save the filtered products data to localStorage
    // localStorage.setItem("filteredProducts", JSON.stringify(filteredProductsDataRedux));

    // Close the download options popup and navigate to the generate-doc page
    setOpenDownloadOptions1(false);
    setTimeout(() => {
      updateBuildDemoStatus(false);
      navigate("/generate-doc");
    }, 500);
  };
  const filteredProductAttributes = distinctProductsFields.map((attribute) => {
    if (typeof attribute === 'string') {
      return ![
        "_id",
        "createdAt",
        "updatedAt",
        "closeup_images",
        "front_image",
        "back_image",
        "front_image_base64",
        "back_image_base64",
        "closeup_images_base64",
        "documents",
        "createdBy",
        "currency",
        "createdby",
        "garment_currency",
        "fabric_currency",  // Exclude fabric_currency globally
        "comment",
        "selection",
        "__v",
        "cw_unit",
        "weight_unit"  // Exclude cw_unit globally
      ].includes(attribute) ? attribute : null;
    } else if (typeof attribute === 'object') {
      // Handle fabric_info cases
      const fabricKey = Object.keys(attribute)[0];
      const filteredFields = attribute[fabricKey].filter(
        field => field !== 'cw_unit' && field !== 'fabric_currency' && field !== 'weight_unit'
      );
      
      // Only return fabric_info object if it has fields left after filtering
      if (filteredFields.length > 0) {
        return { [fabricKey]: filteredFields };
      }
      return null;  // Remove the whole fabric_info object if no fields left
    }
    return null;
  }).filter(Boolean); // Filter out null values
  console.log(filteredProductAttributes)
  const specialAttributes = [
    "fabric_supplier",
    "fabric_ref",
    "fob",
    "fabric_price",
  ];
  const checkBoxImageSrc = Object.entries(checkedAttributes).some(
    ([key, value]) => !key.startsWith("closeup_images_base64") && value
  ) ? checkBoxIcon : checkBoxCheckedIcon;
  console.log(checkBoxImageSrc)
  const sortedProductAttributes = [
    ...filteredProductAttributes.filter(
      (attribute) => !specialAttributes.includes(attribute)
    ),
    ...filteredProductAttributes.filter((attribute) =>
      specialAttributes.includes(attribute)
    ),
  ];
  
  // Extract fabric attributes from fabric_info
  const fabricAttributes = (distinctProductsFields || []).flatMap((field) => {
    const fabricInfoKeys = [];
  
    // Ensure fabric_info exists and is an array
    if (Array.isArray(field.fabric_info)) {
      field.fabric_info.forEach((_, index) => {
        fabricInfoKeys.push(`fabric_info_${index + 1}`);
      });
    }
  
    return fabricInfoKeys;
  });
  const fabricAttributeDisplayNames = {};
  
  // Ensure distinctProductsFields is an array
  (distinctProductsFields || []).forEach((item) => {
    // Ensure fabric_info exists and is an array
    if (Array.isArray(item.fabric_info)) {
      item.fabric_info.forEach((fabric, index) => {
        Object.keys(fabric).forEach((key) => {
          fabricAttributeDisplayNames[`fabric_info_${index + 1}_${key}`] = `${key} (${index + 1})`;
        });
      });
    }
  });

const attributeDisplayNames = {
  description: "Description",
  season: "Season",
  product_style: "Style Name / Reference. No",
  gender: "Gender",
  categories: "Categories",
  content: "Content",
  construction: "Construction",
  shrinkage: "Shrinkage",
  weight: "Weight",
  cw: "Cuttable Width",
  best_seller: "Best Seller",
  phase: "Phase",
  brand: "Brand",
  sub_brand: "Sub Brand",
  department: "Department",
  shipment_date: "Shipment Date",
  order_quantity: "Order Quantity (Pcs)",
  garment_fob: "Garment FOB (USD)",
  fabric_supplier: "Fabric Mill / Supplier",
  fabric_ref: "Fabric Reference. No",
  fob: "Fabric FOB (USD)",
  fabric_price: "Fabric Price ($)",
  division: "Division",
  yardage: "Yardage",
  customer: "Customer",
  ...fabricAttributeDisplayNames, // Include fabric attribute names
};
const finalSortedAttributes = [
  ...sortedProductAttributes,
  ...fabricAttributes,
];
console.log("finalSortedAttributes",finalSortedAttributes)
 console.log("checkedAttributes",checkedAttributes)
 console.log("checkedAttributes.content",checkedAttributes.fabric_info?.[0]?.content)
 console.log("checkedAttributes.construction",checkedAttributes.fabric_info?.[0]?.shrinkage)
 console.log(sortedProductAttributes)
  return (
    <div className="view-products">
      {buildDemoStatus && (
        <div className="build-demo-footer">
          <div className="demo-footer-button-container">
            <div className="selected-demo-products-count">
              {selectedProducts.length} Products selected
            </div>
            <button
              className="popup-cancel-button"
              onClick={buildDemoCancelClick}
            >
              Cancel
            </button>
            <button
              className="popup-confirm-button"
              onClick={buildDemoConfirmClick}
              disabled={selectedProducts.length === 0}
              style={{
                cursor: selectedProducts.length > 0 ? "pointer" : "not-allowed",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
      <Popup
        open={openDownloadOptions}
        closeOnDocumentClick={false}
        onClose={closeModal}
      >
        <div className="popup-container-in">
          <div className="attribute-popup-title">
            {" Attribute Settings"}
            <button
              className="popup-close-button"
              onClick={buildDemoPopupCloseClick}
            >
              <MdClose />
            </button>
          </div>

          {demoLoading ? (
            // Show only the spinner if demoLoading is true
            <div className="circular-progress-container">
              <CircularProgress />
            </div>
          ) : (
            // Show the rest of the content if demoLoading is false
            <div className="attribute-popup-content">
              <div className="attribute-subtitle-container">
                <div className="attribute-subtitle-container-in">
                  <div className="step1-div ongoing">
                    <img src={stepOngoing} alt="" /> <span>Image Settings</span>
                  </div>
                  <div className="hr-line-div"></div>
                  <div className="step2-div next">
                    <img src={nextStep} alt="" /> <span>Field Settings</span>
                  </div>
                </div>
              </div>
              <div className="select-all-div">
                <span>Select the images you want to show</span>
                {/* <button
            className="select-all-button"
            onClick={handleSelectAllImages}
          >
            <img
              src={checkBoxIcon}
              disabled={distinctProductImageFields.length === 0}
              alt=""
            />
            Select All
          </button> */}
              </div>
              <div className="hr-line-div-main"></div>
              <div className="attribute-image-options-list">
                {Object.entries(checkedAttributes).some(
                  ([key, value]) => key.startsWith("closeup") && value
                ) && (
                  <div className="fields-note-container">
                    Note: You can only select up to 15 fields if you choose more
                    than 2 images.
                  </div>
                )}
                {distinctProductImageFields.map((attribute) => (
                  <div className="each-image-option" key={attribute}>
                    <FormControlLabel
                      componentsProps={{
                        typography: {
                          sx: {
                            fontFamily: "Calibri",
                            letterSpacing: 0,
                            color: "rgba(33, 43, 54, 1)",
                          }, // Apply fontFamily to label text
                        },
                      }}
                      control={
                        <Checkbox
                          disabled={
                            attribute === "front_image_base64" ||
                            attribute === "back_image_base64"
                          }
                          checked={
                            attribute === "front_image_base64" ||
                            attribute === "back_image_base64" ||
                            checkedAttributes[attribute] ||
                            false
                          }
                          sx={{
                            color: "rgb(38, 64, 138)",
                            "&.Mui-checked": {
                              color: "rgb(38, 64, 138)",
                            },
                          }}
                          onChange={() => {
                            console.log(`Checkbox clicked: ${attribute}`);
                            handleCheckboxChange(attribute);
                          }}
                        />
                      }
                      label={imageAttributeDisplayNames[attribute]}
                    />
                  </div>
                ))}
              </div>
              <div className="attribute-button-container ">
                <div className="demo-popup-footer-button-container">
                  <button className="popup-cancel-button" onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    className="popup-confirm-button"
                    onClick={buildDemoPopup1ConfirmClick}
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popup>
      <Popup
        open={openDownloadOptions1}
        closeOnDocumentClick={false}
        onClose={closeModal1}
      >
        <div className="popup-container-in">
          <div className="attribute-popup-title">
            {" Attribute Settings"}
            <button
              className="popup-close-button"
              onClick={buildDemoPopupCloseClick}
            >
              <MdClose />
            </button>
          </div>
          <div className="attribute-popup-content">
            <div className="attribute-subtitle-container">
              <div className="attribute-subtitle-container-in">
                <div className="step1-div completed">
                  <img src={stepCompleted} alt="" /> <span>Image Settings</span>
                </div>
                <div className="hr-line-div"></div>
                <div className="step2-div ongoing">
                  <img src={stepOngoing} alt="" /> <span>Field Settings</span>
                </div>
              </div>
            </div>
            <div className="select-all-div">
              <span>Select the details you want to show</span>
              <button
                className="select-all-button"
                onClick={handleSelectAll}
                disabled={
                  Object.entries(checkedAttributes).some(
                    ([key, value]) =>
                      key.startsWith("closeup_images_base64") && value
                  ) || sortedProductAttributes.length === 0
                }
              >
                <img
                  src={checkBoxClicked ? checkBoxCheckedIcon : checkBoxIcon}
                  alt=""
                />
                Select All
              </button>
            </div>
            <div className="hr-line-div-main"></div>
            {Object.entries(checkedAttributes).some(
              ([key, value]) => key.startsWith("closeup") && value
            ) && (
              <div className="fields-note-container">
                Note: You can only select up to 15 fields if you choose more
                than 2 images.
              </div>
            )}
            <div>
              {/* Separate div for non-fabric_info attributes */}
              <div className="attribute-options-list">
                {finalSortedAttributes.map((attribute, index) => {
                  if (
                    typeof attribute !== "object" ||
                    !Object.keys(attribute)[0].startsWith("fabric_info_")
                  ) {
                    return (
                      <FormControlLabel
                        key={attribute}
                        componentsProps={{
                          typography: {
                            sx: {
                              fontFamily: "Calibri",
                              letterSpacing: 0,
                              color: "rgba(33, 43, 54, 1)",
                            }, // Apply fontFamily to label text
                          },
                        }}
                        sx={{
                          paddingLeft: "12px",
                          fontFamily: "Calibri",
                        }}
                        label={attributeDisplayNames[attribute]}
                        control={
                          <Checkbox
                            style={{ fontFamily: "Calibri" }}
                            disabled={attribute === "description"}
                            checked={checkedAttributes[attribute] || false}
                            onChange={() => handleCheckboxChange(attribute)}
                            sx={{
                              color: "rgb(38, 64, 138)",
                              fontFamily: "Calibri",
                              "&.Mui-checked": {
                                color: "rgb(38, 64, 138)",
                              },
                            }}
                          />
                        }
                      />
                    );
                  }
                  return null;
                })}
              </div>

              {/* Separate div for fabric_info attributes */}
              <div className="fabric-attributes">
                {finalSortedAttributes.map((attribute, index) => {
                  if (
                    typeof attribute === "object" &&
                    Object.keys(attribute)[0].startsWith("fabric_info_")
                  ) {
                    return Object.entries(attribute).map(
                      ([fabricInfoKey, fabricAttributes]) => {
                        const fabricIndex =
                          parseInt(fabricInfoKey.split("_")[2], 10) - 1;

                        return (
                          <div key={fabricInfoKey}>
                            <h4 className="fabric-title">
                              {extractFabricInfoTitle(fabricInfoKey)}
                            </h4>
                            <div className="attribute-options-list fabric">
                              {fabricAttributes.map((fabricAttribute) => (
                                <FormControlLabel
                                  key={`${fabricInfoKey}.${fabricAttribute}`}
                                  style={{ fontFamily: "Calibri" }}
                                  componentsProps={{
                                    typography: {
                                      sx: {
                                        fontFamily: "Calibri",
                                        letterSpacing: 0,
                                        color: "rgba(33, 43, 54, 1)",
                                      }, // Apply fontFamily to label text
                                    },
                                  }}
                                  sx={{
                                    paddingLeft: "12px",
                                    fontFamily: "Calibri",
                                  }}
                                  label={
                                    fabricAttributeDisplayNamesLabel[
                                      fabricAttribute
                                    ] || fabricAttribute
                                  }
                                  control={
                                    <Checkbox
                                      checked={
                                        checkedAttributes.fabric_info?.[
                                          fabricIndex
                                        ]?.[fabricAttribute] || false
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(
                                          null,
                                          fabricInfoKey,
                                          fabricAttribute
                                        )
                                      }
                                      sx={{
                                        fontFamily: "Calibri",
                                        color: "rgb(38, 64, 138)",
                                        "&.Mui-checked": {
                                          color: "rgb(38, 64, 138)",
                                        },
                                      }}
                                    />
                                  }
                                />
                              ))}
                            </div>
                          </div>
                        );
                      }
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div className="attribute-button-container popup-2">
              <button
                onClick={buildDemoPopup2BackClick}
                className="popup-2-back-button"
              >
                Back
              </button>
              <div className="demo-popup-footer-button-container">
                <button className="popup-cancel-button" onClick={closeModal1}>
                  Cancel
                </button>
                <button
                  className="popup-confirm-button"
                  onClick={buildDemoPopup2ConfirmClick}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <div className="view-products-container">
        {(products && products?.length > 0) ||
        (allProducts && allProducts?.data && allProducts?.data?.length > 0) ? (
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
              <Toolbar>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      ...(open && {
                        width: "220px",
                      }),
                    }}
                  >
                    <div className="filter-button">
                      <span style={{ color: "#000" }}>Filters</span>
                      <div
                        onMouseEnter={() => setfilterIsHovered(true)}
                        onMouseLeave={() => setfilterIsHovered(false)}
                      >
                        {!filterIsHovered && (
                          <img src={filter} alt="" width={24} height={24} />
                        )}
                        {filterIsHovered && (
                          <img src={filterGif} alt="" width={24} height={24} />
                        )}
                      </div>
                    </div>
                  </IconButton>
                </div>

                <div className="filter-bar">
                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeyPress}
                    />
                    <img
                      src={search}
                      alt=""
                      className="search-icon"
                      onClick={handleSearchClick}
                    />
                  </div>
                  {(role === "Admin" ||
                    role === "SuperAdmin" ||
                    role === "Operator") && (
                    <Link
                      to={!demoSelected ? "/addproduct" : "#"}
                      className="Addproduct-button"
                      onClick={(e) => demoSelected && e.preventDefault()}
                      style={{
                        cursor: !demoSelected ? "pointer" : "not-allowed",
                      }}
                    >
                      <span className="plus">+</span> Add Product
                    </Link>
                  )}
                </div>
              </Toolbar>
            </AppBar>

            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                  overflow: "hidden",
                },
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader
                style={{
                  minHeight: "44px",
                  padding: "0px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #CDE5FF",
                }}
              >
                <span>Filter</span>
                <IconButton onClick={handleDrawerClose}>
                  <img src={close} alt="" />
                </IconButton>
              </DrawerHeader>

              {(searchTerm ||
                selectedCategories.length > 0 ||
                selectedSeason.length > 0 ||
                selectedGender.length > 0 ||
                selectedBrand.length > 0) && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "16px",
                  }}
                >
                  <button className="clear-filter" onClick={handleClear}>
                    Clear Filters
                  </button>
                </div>
              )}
              <div className="dropdown-scroll">
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.categories}
                  name="Categories"
                  selectedOptions={selectedCategories}
                  setSelectedOptions={setSelectedCategories}
                />
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.gender}
                  name="Gender"
                  selectedOptions={selectedGender}
                  setSelectedOptions={setSelectedGender}
                />
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.seasons}
                  name="Season"
                  selectedOptions={selectedSeason}
                  setSelectedOptions={setSelectedSeason}
                />
                <DropdownWithCheckbox
                  options={dropdownValues?.data[0]?.product_brand}
                  name="Brand"
                  selectedOptions={selectedBrand}
                  setSelectedOptions={setSelectedBrand}
                />
              </div>
            </Drawer>
            <Main open={open} className="Main-root">
              {products && products.length > 0 ? (
                <div className="product-list">
                  {products.slice(0, visibleCount).map((product) => (
                    <div
                      key={product._id}
                      ref={(el) => (productRefs.current[product._id] = el)}
                      className="product-card"
                      onClick={() => navigateToProductDetails(product._id)}
                    >
                      {buildDemoStatus && (
                        <div className="product-card-over-build-demo">
                          <FormControlLabel
                            sx={{
                              paddingLeft: "12px",
                            }}
                            componentsProps={{
                              typography: {
                                sx: {
                                  fontFamily: "Calibri",
                                  letterSpacing: 0,
                                  color: "rgba(33, 43, 54, 1)",
                                }, // Apply fontFamily to label text
                              },
                            }}
                            control={
                              <Checkbox
                                checked={selectedProducts.includes(product._id)}
                                onChange={() => addProductstoBuild(product._id)}
                                sx={{
                                  color: "rgb(38, 64, 138)",
                                  "&.Mui-checked": {
                                    color: "rgb(38, 64, 138)",
                                  },
                                }}
                              />
                            }
                          />
                        </div>
                      )}
                      {product.best_seller && (
                        <div className="icon-container">
                          <img
                            src={BestSeller}
                            alt="Best Seller"
                            width={44}
                            height={57}
                          />
                        </div>
                      )}
                      <div className="image-container">
                        <img
                          src={product.front_image}
                          alt={product.description}
                          loading="lazy"
                        />
                      </div>
                      <div className="product-footer">
                        <span className="footer-big-text">
                          {product.description}
                        </span>
                        <span className="footer-small-text">
                          {product.fabric_info[0].content}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : !loading &&
                loading !== "true" &&
                loading !== "1" &&
                products &&
                products.length === 0 ? (
                <div className="no-filteredproducts-container">
                  <div className="filterd-inner-content">
                    <div>
                      <img src={space} alt="" className="no-products-img" />
                    </div>
                    <div>
                      <span className="no-product-text-big">
                        Seems Like No Products For This Filter Here!!
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <GradientCircularProgress />
              )}
              {visibleCount < products.length && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <button onClick={loadMore} className="loadmore">
                    Load {products.length - visibleCount} More Products!
                  </button>
                </div>
              )}
            </Main>
          </Box>
        ) : (
          <div className="no-products-container">
            <div className="inner-content">
              <div>
                <img src={space} alt="" className="no-products-img" />
              </div>
              <div>
                <span className="no-product-text-big">
                  Seems Like No Products Here!!
                </span>
              </div>
              <div>
                <span className="no-product-text-small">
                  Let's Start Filling This Space With Your Products.
                </span>
              </div>
              <div>
                <Link to="/addproduct" className="Addproduct-button2">
                  <span className="plus">+</span> Add Product
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.allProducts.loading,
  dropdownValues: state.dropdownValues.dropDownValues,
  selectedProductsData: state.getProducts.products,
  demoLoading: state.getProducts.loading,
  buildDemoStatus: state.getBuildDemoState.selected,
  selectedFilteredProductsData: state.selectedFilteredProducts.filteredProducts,
  visibleCount: state.loadMore.visibleCount,
});

const mapDispatchToProps = {
  getAllProducts,
  getProductsDropDownValues,
  findProducts,
  updateBuildDemoStatus,
  setSelectedFilteredProducts,
  setVisibleCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

// <DropdownWithCheckbox
//   options={options1}
//   name="Fabrics"
//   selectedOptions={selectedFabrics}
//   setSelectedOptions={setSelectedFabrics}
// />


// const handleCheckboxChange = (attribute) => {
//   setCheckedAttributes((prevState) => {
//     const newState = { ...prevState };
//     let selectionHistory = [...(prevState.selectionHistory || [])];
    
//     // Check if the attribute is a special field
//     const isSpecialField = attribute.startsWith("closeup_images_base64") || 
//                             attribute === "front_image_base64" || 
//                             attribute === "back_image_base64";

//     // Check if any closeup images are selected
//     const anyCloseupImageSelected = Object.keys(newState).some(key =>
//       key.startsWith("closeup_images_base64") && newState[key]
//     );

//     if (isSpecialField) {
//       // Handle special fields
//       if (newState[attribute]) {
//         // Unselecting special field
//         newState[attribute] = false;
//         // Remove from history if it exists
//         selectionHistory = selectionHistory.filter((item) => item !== attribute);
//       } else {
//         // Selecting special field
//         newState[attribute] = true;
//         // Add to history if not already present
//         if (!selectionHistory.includes(attribute)) {
//           selectionHistory.push(attribute);
//         }
//       }
//     } else {
//       // Handle non-special fields with 12-field limit if any closeup image is selected
//       if (newState[attribute]) {
//         // Unselecting non-special field
//         newState[attribute] = false;
//         // Remove from history
//         selectionHistory = selectionHistory.filter((item) => item !== attribute);
//       } else {
//         // Selecting non-special field
//         if (anyCloseupImageSelected) {
//           const nonSpecialSelectedCount = Object.keys(newState)
//             .filter(key => !key.startsWith("closeup_images_base64") &&
//                            key !== "front_image_base64" &&
//                            key !== "back_image_base64" &&
//                            newState[key] === true)
//             .length;

//           if (nonSpecialSelectedCount >= 11) {
//             // Find and unselect the most recently selected non-special attribute
//             const mostRecentlySelected = selectionHistory
//               .filter(item => !item.startsWith("closeup_images_base64") &&
//                               item !== "front_image_base64" &&
//                               item !== "back_image_base64")
//               .pop(); // Get the most recently selected item (last in the history)
//             if (mostRecentlySelected) {
//               newState[mostRecentlySelected] = false;
//               selectionHistory = selectionHistory.filter((item) => item !== mostRecentlySelected);
//             }
//           }
//         }

//         newState[attribute] = true;
//         // Add to history if not already present
//         if (!selectionHistory.includes(attribute)) {
//           selectionHistory.push(attribute);
//         }
//       }
//     }

//     console.log("Checked attributes:", newState);
//     console.log("Selection history:", selectionHistory);

//     // Save the updated history in the state
//     return { ...newState, selectionHistory };
//   });
// };





// const handleCheckboxChange = (attribute, fabricInfoKey = null, fabricField = null) => {
//   setCheckBoxClicked(false);

//   setCheckedAttributes((prevState) => {
//     const newState = { ...prevState };
//     let selectionHistory = [...(prevState.selectionHistory || [])];

//     // Check if the attribute is a special field
//     const isSpecialField = attribute?.startsWith("closeup_images_base64") || 
//                             attribute === "front_image_base64" || 
//                             attribute === "back_image_base64";

//     // Check if any closeup images are selected
//     const closeupImageKeys = Object.keys(newState).filter(key => key.startsWith("closeup_images_base64"));
//     const closeupImageSelectedCount = closeupImageKeys.filter(key => newState[key]).length;

//     const anyCloseupImageSelected = closeupImageSelectedCount > 0;

//     // Handle fabric_info attributes
//     if (fabricInfoKey && fabricField) {
//       const fabricIndex = fabricInfoKey.split('_')[2] - 1; // Extract fabric_info index (e.g., 1 for fabric_info_1)

//       // Ensure fabric_info array exists in the newState
//       if (!newState.fabric_info) {
//         newState.fabric_info = [];
//       }

//       // Initialize empty objects for missing fabric_info indices
//       while (newState.fabric_info.length <= fabricIndex) {
//         newState.fabric_info.push({});
//       }

//       const fabricInfoAttribute = fabricField;

//       if (newState.fabric_info[fabricIndex][fabricInfoAttribute]) {
//         // Unselect fabric_info attribute
//         delete newState.fabric_info[fabricIndex][fabricInfoAttribute];
//         selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.${fabricField}`);

//         // Auto-deselect dependent fields
//         if (fabricInfoAttribute === "cw" && newState.fabric_info[fabricIndex]["cw_unit"]) {
//           delete newState.fabric_info[fabricIndex]["cw_unit"];  // Auto-unselect cw_unit
//           selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.cw_unit`);
//         }
//         if (fabricInfoAttribute === "fabric_price" && newState.fabric_info[fabricIndex]["fabric_currency"]) {
//           delete newState.fabric_info[fabricIndex]["fabric_currency"];  // Auto-unselect fabric_currency
//           selectionHistory = selectionHistory.filter((item) => item !== `${fabricInfoKey}.fabric_currency`);
//         }
//       } else {
//         // Select fabric_info attribute
//         newState.fabric_info[fabricIndex][fabricInfoAttribute] = true;
//         if (!selectionHistory.includes(`${fabricInfoKey}.${fabricField}`)) {
//           selectionHistory.push(`${fabricInfoKey}.${fabricField}`);
//         }

//         // Auto-select dependent fields
//         if (fabricInfoAttribute === "cw" && !newState.fabric_info[fabricIndex]["cw_unit"]) {
//           newState.fabric_info[fabricIndex]["cw_unit"] = true;  // Auto-select cw_unit
//         }
//         if (fabricInfoAttribute === "fabric_price" && !newState.fabric_info[fabricIndex]["fabric_currency"]) {
//           newState.fabric_info[fabricIndex]["fabric_currency"] = true;  // Auto-select fabric_currency
//         }
//       }

//       // Remove the fabric index if it's empty after deselection
//       if (Object.keys(newState.fabric_info[fabricIndex]).length === 0) {
//         newState.fabric_info.splice(fabricIndex, 1);
//       }
//     } else if (isSpecialField) {
//       // Handle special fields
//       if (newState[attribute]) {
//         // Unselecting special field
//         newState[attribute] = false;
//         // Remove from history if it exists
//         selectionHistory = selectionHistory.filter((item) => item !== attribute);
//       } else {
//         // Selecting special field
//         newState[attribute] = true;
//         // Add to history if not already present
//         if (!selectionHistory.includes(attribute)) {
//           selectionHistory.push(attribute);
//         }

//         // Handle the limit for closeup images
//         if (attribute.startsWith("closeup_images_base64")) {
//           if (closeupImageSelectedCount >= 2) {
//             // Find and unselect the earliest selected closeup image
//             const earliestSelectedCloseup = selectionHistory
//               .filter(item => item.startsWith("closeup_images_base64"))
//               .shift(); // Get the earliest selected item (first in the history)
//             if (earliestSelectedCloseup) {
//               newState[earliestSelectedCloseup] = false;
//               selectionHistory = selectionHistory.filter((item) => item !== earliestSelectedCloseup);
//             }
//           }
//         }
//       }
//     } else {
//       // Handle non-fabric_info and non-special fields
//       if (newState[attribute]) {
//         // Unselect attribute
//         newState[attribute] = false;
//         selectionHistory = selectionHistory.filter((item) => item !== attribute);
//       } else {
//         // Selecting non-special field
//         if (anyCloseupImageSelected) {
//           const nonSpecialSelectedCount = Object.keys(newState)
//             .filter(key => !key.startsWith("closeup_images_base64") &&
//                            key !== "front_image_base64" &&
//                            key !== "back_image_base64" &&
//                            newState[key] === true)
//             .length;

//           if (nonSpecialSelectedCount >= 11) {
//             // Find and unselect the most recently selected non-special attribute
//             const mostRecentlySelected = selectionHistory
//               .filter(item => !item.startsWith("closeup_images_base64") &&
//                               item !== "front_image_base64" &&
//                               item !== "back_image_base64")
//               .pop(); // Get the most recently selected item (last in the history)
//             if (mostRecentlySelected) {
//               newState[mostRecentlySelected] = false;
//               selectionHistory = selectionHistory.filter((item) => item !== mostRecentlySelected);
//             }
//           }
//         }

//         newState[attribute] = true;
//         // Add to history if not already present
//         if (!selectionHistory.includes(attribute)) {
//           selectionHistory.push(attribute);
//         }
//       }
//     }

//     return { ...newState, selectionHistory };
//   });
// };