import React, { useEffect, useState } from "react";
import video from "../../images/landingpage-video.mp4";
import leftButton from "../../images/icons/left-button.svg";
import rightButton from "../../images/icons/right-button.svg";
import { connect } from "react-redux";
import { getBestSeller } from "../../redux/action/addProduct";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "react-slick"; // Import Slider from react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";
import "./LandingPage.css";

const LandingPage = ({
  getBestSeller,
  bestseller,
  loading,
  setSelectedGender,
  setActiveItem,
}) => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const productsPerPage = 5;
  const location = useLocation();

  useEffect(() => {
    const activeButton = localStorage.setItem("activeItem", "");
    setActiveItem(activeButton);
    sessionStorage.setItem("previousUrl", window.location.href);
  }, [location, setActiveItem]);
console.log("bestseller",bestseller)
  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails?.user) {
      getBestSeller(userDetails?.user?._id, userDetails?.user?.role);
    }
  }, [getBestSeller]);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />
              {/* Change color to #26408A */}
              <stop offset="50%" stopColor="#196D92" />
              {/* Change color to #196D92 */}
              <stop offset="100%" stopColor="#49883E" />
              {/* Change color to #49883E */}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  if (loading || !bestseller || !bestseller.data) {
    return <GradientCircularProgress />;
  }

  const totalProducts = Math.min(bestseller.data.length, 15);

  const handleViewProductsClick = () => {
    setSelectedGender([]);
    navigate("/dashboard");
  };

  const navigateToProductDetails = (productId) => {
    navigate(`/productdetails/${productId}`);
  };

  const getSliderSettings = (length) => {
    if (length < 3) {
      return {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
    } else if (length <= 6) {
      return {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
      };
    } else if (length <= 10) {
      return {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 4,
        slidesToScroll: 4,
      };
    } else {
      return {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 5,
        slidesToScroll: 5,
      };
    }
  };

  const sliderSettings = getSliderSettings(bestseller.data.length);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="landingPage-video-background">
        <video autoPlay muted loop id="video-bg">
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className="landingpage-main">
        <div className="landingpage-title">
          <span className="landingpage-large-span">
            Your Style’s Headquarters
          </span>
          <span className="landingpage-small-span">
            Revolutionize The Way You Manage Your Products - Simplifying The
            things Like Never Before!
          </span>
          <button
            className="view-products-button"
            onClick={handleViewProductsClick}
          >
            View All Products
          </button>
        </div>
        {bestseller.data.length > 0 && (
          <div className="bestseller">
            <div className="text-container">
              <span className="best-seller-text">Our Bestsellers For You</span>
            </div>

            {bestseller.data.length === 1 ? (
              bestseller.data.map(
                ({ _id, front_image, description, fabric_info }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      key={_id}
                      className="bestseller-card"
                      onClick={() => navigateToProductDetails(_id)}
                    >
                      <div className="bestseller-image-container">
                        <img src={front_image} alt={description} />
                      </div>
                      <div className="bestseller-footer">
                        <span className="bestseller-big-text">
                          {description}
                        </span>
                        <span className="bestseller-small-text">{fabric_info[0].content}</span>
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <Slider {...sliderSettings}>
                {bestseller.data.map(
                  ({ _id, front_image, description, content }) => (
                    <div
                      key={_id}
                      className="bestseller-card"
                      onClick={() => navigateToProductDetails(_id)}
                    >
                      <div className="bestseller-image-container">
                        <img src={front_image} alt={description} />
                      </div>
                      <div className="bestseller-footer">
                        <span className="bestseller-big-text">
                          {description}
                        </span>
                        <span className="bestseller-small-text">{content}</span>
                      </div>
                    </div>
                  )
                )}
              </Slider>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bestseller: state.bestSeller.product,
  allProducts: state.allProducts.allProducts,
  loading: state.bestSeller.loading,
});

const mapDispatchToProps = {
  getBestSeller,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
