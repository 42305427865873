import React, { useState, useEffect } from "react";
import "./ProductDetails.css";
import arrow from "./icons/arrow.svg";
import deleteIcon from "./icons/deleteIcon.svg";
import edit from "./icons/edit.svg";
import pdf from "./icons/pdf.svg";
import deletePopupIcon from "./icons/delete.svg";
import { connect } from "react-redux";
import { findProduct, deleteProduct } from "../../redux/action/addProduct";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import arrowGif from "../../images/icons/arrow.gif";
import editGif from "../../images/icons/edit.gif";
import deleteGif from "../../images/icons/delete.gif";
import BestSeller from "../../images/icons/bestSeller.svg";
import { getUserDetailsFromLocalStorage } from "../LocalStorage";

const ProductDetails = ({ findProduct, product, loading, deleteProduct }) => {
  const [deleteIsHovered, setDeleteIsHovered] = useState(false);
  const [editIsHovered, seteditIsHovered] = useState(false);
  const [backIsHovered, setbackIsHovered] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [role, setRole] = useState("");

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { productId } = useParams();
  const dashboardUrl = "/dashboard";
  const landingPageUrl = "/landingpage";
  const handleEditClick = () => {
    navigate(`/editproduct/${productId}`);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteProduct = async () => {
    await deleteProduct("product", productId);
    navigate("/dashboard");
  };
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  useEffect(() => {
    findProduct(productId);
  }, [findProduct, productId]);

  useEffect(() => {
    const userDetails = getUserDetailsFromLocalStorage();
    if (userDetails?.user) {
    }
    setRole(userDetails.user.role);
  }, []);

  useEffect(() => {
    if (product && product.front_image) {
      setSelectedImage(product.front_image);
    }
  }, [product]);

  const GradientCircularProgress = () => (
    <div className="loading-container">
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#26408A" />{" "}
              <stop offset="50%" stopColor="#196D92" />{" "}
              <stop offset="100%" stopColor="#49883E" />{" "}
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </React.Fragment>
    </div>
  );

  // Usage:
  if (loading || !product) {
    return <GradientCircularProgress />;
  }

  const fabricDetails = [
    {
      label: "Brand",
      value: product.brand,
    },
    {
      label: "Sub Brand",
      value: product.sub_brand,
    },
    {
      label: "Department",
      value: product.department,
    },
    {
      label: "Description",
      value: product.description,
    },
    {
      label: "Season",
      value: product.season,
    },
    {
      label: "Style Name / Reference. No",
      value: product.product_style,
    },
    {
      label: "Gender",
      value: product.gender,
    },
    {
      label: "Categories",
      value: product.categories,
    },
  ];

  const fabricProd = [
    {
      label: "Phase",
      value: product.phase,
    },
    {
      label:
        product.phase === "Production" ? "Shipment Date" : "Development Date",
      value: new Date(product.shipment_date)
        .toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(",", ""),
    },
  ];
  
  // Conditional fields based on product phase
  if (product.phase !== "Development") {
    fabricProd.push(
      {
        label: "Order Quantity (Pcs)",
        value: product.order_quantity,
      },
      {
        label: `Garment FOB (${product.garment_currency || "USD"})`,
        value: product.garment_fob,
      }
    );
  }
  
  // Fields common to both phases
  fabricProd.push(
    {
      label: "Customer",
      value: product.customer,
    },
    {
      label: "Division",
      value: product.division,
    },
    {
      label: "Selection",
      value: product.selection ? "Yes" : "No",
    }
  );
  
  // Conditionally push 'Comment' only if product.selection is true
  if (product.selection) {
    fabricProd.push({
      label: "Comment",
      value: product.comment,
    });
  }
  
  const documents = [
    {
      label: "documents",
      value: product.documents.map((url) => url.split("/").pop()),
    },
  ];

  console.log("selectedImage", selectedImage);
  const handleDashboard = () => {
    const previousUrl = sessionStorage.getItem("previousUrl"); // Retrieve the previous URL

    // Debugging to see the previous URL

    // Check if the previous page is either the dashboard or landing page
    if (previousUrl && previousUrl.includes(dashboardUrl)) {
      setSelectedImage(null);
      navigate(dashboardUrl); // Go back to the previous page
    } else if (previousUrl && previousUrl.includes(landingPageUrl)) {
      setSelectedImage(null);
      navigate(landingPageUrl);
    } else {
      setSelectedImage(null);
      navigate(dashboardUrl); // Navigate to the dashboard
    }
  };
  console.log("data", product.selection);
  return (
    <div>
      <div className="productdetails-container">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: "502px",
              height: "333px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "32px 80px",
            },
          }}
          sx={{ "& .MuiDialogContent-root": { overflow: "hidden" } }}
        >
          <img src={deletePopupIcon} alt="" width={100} height={100} />
          <DialogTitle id="alert-dialog-title">
            {"Are You Sure You Want to Delete ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This Product Will Be Deleted Immediately.
              <br /> You Can’t Undo This Action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                color: "#26408A",
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "140px",
                height: "40px",
                borderRadius: "20px",
                border: "2px solid transparent",
                backgroundImage: `linear-gradient(white, white), linear-gradient(to right, #26408A, #196D92, #49883E)`,
                backgroundOrigin: "padding-box, border-box",
                backgroundClip: "padding-box, border-box",
                marginRight: "24px",
                textTransform: "capitalize",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteProduct}
              autoFocus
              sx={{
                position: "relative",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: 600,
                padding: "0.5rem 1rem",
                cursor: "pointer",
                width: "140px",
                height: "40px",
                borderRadius: "20px",
                border: "2px solid transparent",
                backgroundColor: "#BA1A1A",
                color: "#fff",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#BA1A1A",
                  border: "2px solid #BA1A1A",
                  textTransform: "capitalize",
                },
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="productdetails-functions">
          <div
            style={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <div>
              {!backIsHovered && (
                <img
                  src={arrow}
                  alt=""
                  width={32}
                  height={32}
                  className="backicon"
                  onClick={handleDashboard}
                  onMouseEnter={() => setbackIsHovered(true)}
                  onMouseLeave={() => setbackIsHovered(false)}
                />
              )}
              {backIsHovered && (
                <img
                  src={arrowGif}
                  alt=""
                  width={32}
                  height={32}
                  className="backicon"
                  onClick={handleDashboard}
                  onMouseEnter={() => setbackIsHovered(true)}
                  onMouseLeave={() => setbackIsHovered(false)}
                />
              )}
            </div>
            <div>
              <span className="back-navigate">{product.description}</span>
            </div>
          </div>
          {role !== "Marketing" && (
            <div className="icons-align">
              <div className="view-product-buttons" onClick={handleEditClick}>
                <div
                  onMouseEnter={() => seteditIsHovered(true)}
                  onMouseLeave={() => seteditIsHovered(false)}
                >
                  {!editIsHovered && (
                    <img
                      src={edit}
                      id="editIcon"
                      alt=""
                      width={34}
                      height={34}
                    />
                  )}
                  {editIsHovered && (
                    <img
                      src={editGif}
                      id="editIcon"
                      alt=""
                      width={34}
                      height={34}
                    />
                  )}
                </div>
                <div>
                  <span className="icon-text">Edit</span>
                </div>
              </div>
              <div
                onMouseEnter={() => setDeleteIsHovered(true)}
                onMouseLeave={() => setDeleteIsHovered(false)}
                onClick={handleClickOpen}
                className="view-product-buttons"
              >
                <div>
                  {" "}
                  {!deleteIsHovered && (
                    <img
                      src={deleteIcon}
                      id="deleteIcon"
                      className="icon"
                      alt=""
                      width={32}
                      height={32}
                    />
                  )}
                  {deleteIsHovered && (
                    <img
                      src={deleteGif}
                      id="deleteIcon"
                      className="icon"
                      alt=""
                      width={32}
                      height={32}
                    />
                  )}
                </div>
                <div>
                  <span className="icon-text">Delete</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="product-main">
          <div className="product-image-container">
            <div className="side-card">
              {product && product.front_image && (
                <div
                  className="product-images"
                  style={{
                    border:
                      selectedImage === product.front_image
                        ? "3px solid transparent"
                        : "none",
                    background:
                      selectedImage === product.front_image
                        ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                        : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                  }}
                  onClick={() => handleImageClick(product.front_image)}
                >
                  <div
                    className={`image-wrapper ${
                      selectedImage === product.front_image
                        ? "active-image"
                        : ""
                    }`}
                  >
                    <img
                      src={product.front_image}
                      alt=""
                      width="100%"
                      className="full-image"
                      loading="lazy"
                    />
                  </div>
                </div>
              )}
              {product && product.back_image && (
                <div
                  className="product-images"
                  style={{
                    border:
                      selectedImage === product.back_image
                        ? "3px solid transparent"
                        : "none",
                    background:
                      selectedImage === product.back_image
                        ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                        : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                  }}
                  onClick={() => handleImageClick(product.back_image)}
                >
                  <div
                    className={`image-wrapper ${
                      selectedImage === product.back_image ? "active-image" : ""
                    }`}
                  >
                    <img
                      src={product.back_image}
                      alt=""
                      width="100%"
                      className="full-image"
                      loading="lazy"
                    />
                  </div>
                </div>
              )}

              {product.closeup_images.map((image, index) => (
                <div
                  key={index}
                  className="product-images"
                  style={{
                    border:
                      selectedImage === image
                        ? "3px solid transparent"
                        : "none",
                    background:
                      selectedImage === image
                        ? "linear-gradient(white, white) padding-box, linear-gradient(to right, #26408A, #196D92, #49883E) border-box"
                        : "linear-gradient(white, white) padding-box, linear-gradient(to right, transparent, transparent) border-box",
                  }}
                  onClick={() => handleImageClick(image)}
                >
                  <div
                    className={`image-wrapper ${
                      selectedImage === image ? "active-image" : ""
                    }`}
                  >
                    <img
                      src={image}
                      alt=""
                      width="100%"
                      className="full-image"
                      loading="lazy"
                    />
                  </div>
                </div>
              ))}
            </div>
            <div style={{ position: "relative", width: "calc(100% - 130px)" }}>
              {product.best_seller && (
                <div className="bestseller-icon">
                  <img src={BestSeller} alt="" width={44} height={57} />
                </div>
              )}
              <div className="full-image-container">
                <img
                  src={selectedImage}
                  alt=""
                  className="full-image"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="fabric-container">
            <div className="fabric-header">
              <span className="fabric-info">Product Basic Info</span>
            </div>
            <div className="fabric-details-container">
              {fabricDetails.map(({ label, value }, index) => (
                <div className="fabric-align">
                  <div key={index} className="fabric-details">
                    <span className="fabric-label">{label}</span>
                    <span className="fabric-value">{value || "-"}</span>
                  </div>{" "}
                  <div className="fabric-line"></div>
                </div>
              ))}
            </div>
            {product.fabric_info.map((fabric, fabricIndex) => (
              <div key={fabricIndex}>
                <div className="fabric-header">
                  <span className="fabric-info">
                    Fabric {fabricIndex + 1} Info
                  </span>
                </div>
                <div className="fabric-details-container">
                  {[
                    { label: "Fabric Reference. No", value: fabric.fabric_ref },
                    { label: "Content", value: fabric.content },
                    { label: "Construction", value: fabric.construction },
                    {
                      label: "Fabric Mill / Supplier",
                      value: fabric.fabric_supplier,
                    },
                    { label: "Shrinkage", value: fabric.shrinkage },
                    {
                      label: `Weight (${fabric.weight_unit})`,
                      value: fabric.weight,
                    },
                    {
                      label: `Cuttable Width (${fabric.cw_unit})`,
                      value: fabric.cw,
                    },
                    {
                      label: `Fabric Price (${
                        fabric.fabric_currency || "USD"
                      })`,
                      value: fabric.fabric_price,
                    },
                    { label: "Yardage", value: fabric.yardage },
                  ].map(({ label, value }, index) => (
                    <div className="fabric-align">
                      <div key={index} className="fabric-details">
                        <span className="fabric-label">{label}</span>
                        <span className="fabric-value">{value || "-"}</span>
                      </div>{" "}
                      <div className="fabric-line"></div>
                      </div>
                  ))}
                </div>
              </div>
            ))}

            <div className="fabric-header">
              <span className="fabric-info">Phase</span>
            </div>
            <div className="fabric-details-container">
              {fabricProd.map(({ label, value }, index) => (
                <div className="fabric-align">
                  <div key={index} className="fabric-details">
                    <span className="fabric-label">{label}</span>
                    <span className="fabric-value">{value || "-"}</span>
                  </div>
                          <div className="fabric-line"></div>

                </div>
              ))}
            </div>
            {product.phase === "Production" && product.documents.length > 0 && (
              <div className="fabric-details">
                <span className="fabric-label">Documents</span>
                <div className="fabric-doc">
                  {documents[0].value.map((value, index) => (
                    <div key={index} className="doc-items">
                      <img src={pdf} alt="" />
                      <span className="fabric-value" style={{overflow: "hidden",
    whiteSpace: "nowrap"}}>{value || "-"}</span>
                    </div>
                  ))}
                </div>

                <hr />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  product: state.product.product,
  loading: state.product.loading,
  deletedProduct: state.deletedProduct.product,
});

const mapDispatchToProps = {
  findProduct,
  deleteProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
